<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="我的收稿" />
			<div class="ct_box">
				<orderNav ref="orderNav" num="draft_num" @navTab="navTab"
					:navId="$store.state.manuscript.category_id" />
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker value-format="timestamp" @change="getList" v-model="time" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<div class="order_search flex">
							<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
							<el-input class="order_search_inp" v-model="title" placeholder="输入标题/订单号"
								@keyup.enter.native="getList"></el-input>
							<button class="search_but" @click="getList()">搜索</button>
						</div>
						<medium_search @searchMedium="searchMedium" />
					</div>
					<orderStatus width="550" :status_list="status_list" ref="orderStatusTab" type="draft_status_num"
						@statusTab="statusTab" :category_id="manuscript.category_id"
						:order_status="manuscript.order_status" />
				</div>
				<table class="table">
					<thead>
						<tr>
							<td>订单号</td>
							<td>标题</td>
							<td
								v-if="$store.state.manuscript.category_id == 2 || $store.state.manuscript.category_id == 7">
								{{ $store.state.manuscript.category_id == 2 ? '所属平台' : '平台' }}</td>
							<td>发布价</td>
							<td>发布链接</td>
							<td>状态</td>
							<td>溢价</td>
							<td>发稿媒介</td>
							<td>发布时间</td>
							<td>创建时间</td>
							<td>撤稿时间</td>
							<td>撤稿原因</td>
							<td class="caozuo">操作</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="index">
							<td>
								{{ item.sn }}
								<timeLimit :limitNum="item.limit_time" />
							</td>
							<td>
								<td_remark :text="item.title" />
							</td>
							<td
								v-if="$store.state.manuscript.category_id == 2 || $store.state.manuscript.category_id == 7">
								{{ item.portal_title }}</td>
							<td>{{ item.medium_price }}</td>
							<td style="color: #108CDD;cursor: pointer;">
								<span style="font-size: 1.2rem;" @click="ChainedShow(item)"
									v-if="item.order_url != ''">查看</span>
								<span style="font-size: 1.2rem;" v-else>-</span>
							</td>
							<td :style="{
		'color': item.order_status == 0 ? '#000' : item.order_status == 1 || item.order_status == 3 ? '#FF8400' : item.order_status == 2
			? '#00B052' : '#FF0000'
	}">{{ status_list[item.order_status + 1].title }}
								<span v-show="item.order_status == 0 || item.order_status == 1"
									style="color: #FF0000;">{{
		item.is_limit == 1 ? '[已超时]' : '' }}</span>
								<td_remark v-if="item.reason4.reason" :text="'原因:' + item.reason4.reason" />
							</td>
							<td>
								<manuscript_premium_button @getList="getList" :list="item" />
							</td>
							<td>{{ item.medium_title }}</td>
							<td>{{ item.publish_time }}</td>
							<td>{{ item.createtime }}</td>
							<td>{{ item.withdraw_time ? item.withdraw_time : '暂无' }}</td>
							<td>
								<td_remark v-show="item.reason4 != '' && item.reason == ''" :text="item.reason4.id" />
								<td_remark v-show="item.reason4 != '' && item.reason == ''"
									:text="item.reason4.reason" />
								<td_remark v-show="item.reason != ''" :text="item.reason.id" />
								<td_remark v-show="item.reason != ''" :text="item.reason.reason" />
							</td>
							<td class="ms_td flex" style="flex-wrap: wrap;justify-content: space-between;">
								<span class="ms_but" @click="lookShow(item.id)">查看稿件</span>
								<span v-if="item.order_status == 0 && item.is_limit == 0" class="ms_but"
									@click="putWord(item)">收稿</span>
								<span
									v-if="item.order_status == 1 && item.is_limit == 0 || item.order_status == 4 && item.is_limit == 0 && item.order_url == ''"
									class="ms_but" @click="outWordShow(item)">出稿</span>

								<span v-if="item.order_status == 3" class="ms_but" @click="refundFn(item)">同意退款</span>
								<span v-if="item.order_status == 3" class="ms_but"
									@click="reWord(item, 4, '拒绝退款')">拒绝退款</span>
								<span v-if="item.order_status == 6" class="ms_but" @click="turnFn(item)">同意撤稿</span>
								<span
									v-if="item.order_status == 2 || item.order_status == 4 && item.is_limit == 0 && item.order_url != ''"
									class="ms_but" @click="EditShow(item)">编辑</span>
								<span
									v-if="item.order_status == 0 || item.order_status == 4 && item.is_limit == 0 && item.order_url == ''"
									class="ms_but" @click="reWord(item, 5, '拒稿')">拒稿</span>
								<span v-if="item.order_status == 1 || item.order_status == 2" class="ms_but"
									@click="reWord(item, 5, '退稿')">退稿</span>

								<span v-if="item.order_status == 6" class="ms_but"
									@click="reWord(item, 4, '拒绝撤稿')">拒绝撤稿</span>
								<!-- <p v-if="item.order_status == 5">{{ item.order_status == 5 ? '---' : '' }}</p> -->

							</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
			<order_link_poup :linkList="ChainedItem" :linkShow="Chained" @close="Chained = false" />
			<!-- 出稿/编辑 -->
			<manuscript_draft ref="manuscript_draft" :outItem="outItem"  :link_title="link_title" @getList="getList" />
			<!-- 传入order_type_ 如果popupObj有type这个值&& popupObj.type == true -->
			<rejectionPoup :order_type="popupObj.type === false ? popupObj.type : ''" :himt="popupObj.himt"
				:order_status="order_status" :type_id="popupObj.type_id" :placeholder="popupObj.placeholder"
				:title="popupObj.title" :rejectShow="rejectShow" :rejectItem="rejectItem" @rejectComfirm="rejectComfirm"
				@rejectClose="rejectShow = false" />
			<!-- <rejectionPoup placeholder="请输入退稿原因" title="退稿原因" :rejectShow="rejectShow" :rejectItem="rejectItem"
				@rejectComfirm="getList, rejectShow = false" @rejectClose="rejectShow = false" /> -->
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import timeLimit from '@/components/timeLimit.vue'
import rejectionPoup from '@/components/rejectionPoup.vue'
import { timeCut } from '@/util/timeCut'
import orderNav from '@/components/orderNav.vue'
import { mapState } from 'vuex'
import medium_search from '@/components/medium_search.vue'
import orderStatus from '@/components/orderStatus.vue'
import order_link_poup from '@/components/order_link_poup.vue'
import manuscript_draft from '@/components/manuscript_draft.vue'
import manuscript_premium_button from '@/components/manuscript_premium_button.vue'
export default {
	components: {
		Title,
		rejectionPoup,
		timeLimit,
		orderNav,
		medium_search,
		orderStatus,
		order_link_poup,
		manuscript_draft,
		manuscript_premium_button
	},
	created() {
		this.$store.commit('childTab', '我的收稿')
		this.getList()
	},
	computed: {
		...mapState(['manuscript'])
	},
	watch: {
		tableData: {
			handler(newVal) {
				this.$refs.orderStatusTab.getNum(this.$store.state.manuscript.category_id)
				this.$refs.orderNav.getMediaNav()
			}
		}
	},
	data() {
		return {
			time: '',
			title: '',
			status_list: [
				{
					id: '',
					title: '全部',
				}, {
					id: 0,
					title: '未处理',
				}, {
					id: 1,
					title: '已收稿'
				},
				{
					id: 2,
					title: '已出稿'
				},
				{
					id: 3,
					title: '申请退款'
				},
				{
					id: 4,
					title: '拒绝退款'
				},
				{
					id: 5,
					title: '撤稿'
				},
				{
					id: 6,
					title: '限时订单'
				},
				{
					id: 7,
					title: '退稿'
				},

			],
			fullscreenLoading: false, //loding
			medium_name: '',
			page: 1,
			total_page: 0,
			count: 0,
			tableData: [], //数据列表
			Chained: false, // 查看链接
			ChainedItem: {},
			outItem: {}, //出稿要拿到的数据
			linkShow: false, //填写链接弹窗
			link_title: '出稿链接填写',
			WorkOrder: false, //提交工单弹窗
			work: '',
			rejectShow: false,
			rejectItem: {},
			order_status: '',
			popupObj: {
				type_id: 0,
				himt: '',
				title: '',
				placeholder: '',

			},
		}
	},
	methods: {
		statusTab(item) {
			this.manuscript.order_status = item
			this.page = this.pageChangeOne()
			this.getList()
		},
		searchMedium(title) {
			this.medium_name = title
			this.page = this.pageChangeOne()
			this.getList()
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.getList()
		},
		navTab(id) {
			this.manuscript.category_id = id
			this.getList()
		},
		statusShow(item) {
			this.status = item
			this.page = this.pageChangeOne()
			this.getList()
		},
		wordOrderShow() {
			this.WorkOrder = true
		},
		lookShow(item) {
			const { href } = this.$router.resolve({
				name: 'lookdetails',
				query: {
					id: item
				}
			})
			window.open(href, '_blank',)
		},
		rejectComfirm() {
			this.rejectShow = false
			this.getList()
		},
		getList() {
			this.fullscreenLoading = true
			let data = {}
			data.page = this.page
			data.limit = 20
			if (this.time) {
				data.start_time = this.time[0] / 1000
				data.end_tiem = this.time[1] / 1000
			}
			if (this.manuscript.order_status.id !== '') {
				data.order_status = this.manuscript.order_status.id
			}
			if (this.title !== '') {
				data.title = this.title
			}
			if (this.medium_name !== '') {
				data.medium_name = this.medium_name
			}
			data.category_id = this.manuscript.category_id
			this.curlGet('/api/users/order/draft', data).then(res => {
				// console.log(res);
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
						if (this.tableData[index].withdraw_time) {
							this.tableData[index].withdraw_time = timeCut(this.tableData[index].withdraw_time * 1000)
						}
						if (this.tableData[index].publish_time) {
							this.tableData[index].publish_time = timeCut(this.tableData[index].publish_time * 1000)
						}
					})

				}
			})

			this.fullscreenLoading = false

		},
		// 收稿
		putWord(item) {
			this.$confirm('确认收稿?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.curlPost('/api/users/order/edit', {
					id: item.id,
					status: 1,
				}).then(res => {
					console.log(res);
					if (res.data.code) {
						this.status = {
							id: 1,
							title: '已收稿'
						},
							this.getList()
						this.$message({
							type: 'success',
							message: '收稿成功'
						});
					}
				})

			})
		},
		// 拒稿/退稿
		reWord(item, status, type) {
			this.rejectItem = JSON.parse(JSON.stringify(item))
			this.order_status = status
			this.rejectShow = true
			this.popupObj = {
				himt: '',
				title: '',
				placeholder: '',
				type_id: 0,

			}
			if (type == '拒绝退款') {
				this.popupObj.type = false
				this.popupObj.himt = '提示：此稿件确定要拒绝退款吗?'
				this.popupObj.title = '拒绝退款'
				this.popupObj.placeholder = '请输入拒绝退款原因'
			} else if (type == '拒稿') {
				this.popupObj.type_id = 2
				this.popupObj.himt = '提示：此稿件确定要拒稿吗?'
				this.popupObj.title = '拒稿'
				this.popupObj.placeholder = '请输入拒稿原因'
			} else if (type == '退稿') {
				this.popupObj.himt = '提示：此稿件确定要退稿吗?'
				this.popupObj.title = '退稿'
				this.popupObj.placeholder = '请输入退稿原因'
			} else if (type == '拒绝撤稿') {
				this.popupObj.himt = '提示：此稿件确定要拒绝撤稿吗?'
				this.popupObj.title = '拒绝撤稿'
				this.popupObj.placeholder = '请输入拒绝撤稿原因'
			}
		},
		// 同意退款
		refundFn(item) {
			this.$confirm('是否同意用户本次的退款?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlPost('/api/users/order/edit', {
					id: item.id,
					status: 5,
				}).then(res => {
					console.log(res);
					if (res.data.code) {
						this.getList()
						this.status = {
							id: 5,
							title: '撤稿'
						}
					}
				})
				this.$message({
					type: 'success',
					message: '退款成功'
				});
			})

		},
		// 同意撤稿
		turnFn(item) {
			console.log(item);
			this.$confirm('是否同意撤稿?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.curlPost('/api/users/order/edit', {
					id: item.id,
					status: 5,
				}).then(res => {
					console.log(res);
					if (res.data.code) {
						this.getList()

						this.$message({
							type: 'success',
							message: '已撤稿'
						});
					}
				})

			})

		},
		// 出稿链接显示
		outWordShow(item) {
			this.$refs.manuscript_draft.linkShow()
			this.link_title = '出稿链接填写'
			this.outItem = item
			console.log(item);
			// 给出类别
			this.$set(this.outItem, 'but_type', 'create')
			// this.outItem.but_type = 'create'
		},
		// 编辑稿件显示链接
		EditShow(item) {
			this.$refs.manuscript_draft.linkShow()
			this.outItem = item
			this.$set(this.outItem, 'but_type', 'update')
			this.link_title = '编辑稿件链接'
			console.log(this.outItem);

		},
		// 查看链接
		ChainedShow(item) {
			this.Chained = true
			this.ChainedItem = item
			console.log(this.ChainedItem);
		},
	},
	beforeDestroy() {
		clearTimeout(timer)
	}
}
</script>

<style lang="scss" scoped>
@import '@/scss/Manuscript';
</style>