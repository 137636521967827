<template>
    <div style="width:auto;margin: 0 20px;">
        <!-- 按钮只会在未处理 发布中 已出稿显示 -->
        <div class="media_premium_box flex">
            <div class="flex" style="flex-direction: column;">
                <div class="flex" style="flex-direction: column;"
                    v-if="(list.order_status == 0 || list.order_status == 1 || list.order_status == 2) && list.premium_status === 0">
                    <p style="color: rgba(222, 13, 13, 1);margin-bottom: 5px;">￥{{ list.premium_price }}</p>
                    <template>
                        <el-popconfirm @confirm="_prmium_(1)" cancel-button-text="取消" title="是否同意溢价">
                            <el-button slot="reference" type="primary" size="mini" round plain>同意溢价</el-button>
                        </el-popconfirm>
                    </template>
                    <template>
                        <el-popconfirm @confirm="_prmium_(2)" cancel-button-text="取消" title="是否拒绝溢价">
                            <el-button style="margin-top: 10px;" slot="reference" type="primary" size="mini" round
                                plain>拒绝溢价</el-button>
                        </el-popconfirm>
                    </template>
                    <div class="reason_box">
                        <el-tooltip class="item" effect="dark" :content="list.premium_reason" placement="top">
                            <el-button style="border: 0;color: rgba(16, 140, 221, 1);">[查看原因]</el-button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="flex" style="flex-direction: column;" v-else-if="list.premium_status === 3">
                    <p style="color: rgba(222, 13, 13, 1);margin-bottom: 5px;">￥{{ list.premium_price }}</p>
                    <template>
                        <el-popconfirm @confirm="_prmium_(5)" cancel-button-text="取消" title="是否同意退款">
                            <el-button slot="reference" type="primary" size="mini" round plain>同意退款</el-button>
                        </el-popconfirm>
                    </template>
                    <template>
                        <el-popconfirm @confirm="_prmium_(4)" cancel-button-text="取消" title="是否拒绝退款">
                            <el-button style="margin-top: 10px;" slot="reference" type="primary" size="mini" round
                                plain>拒绝退款</el-button>
                        </el-popconfirm>
                    </template>
                    <div class="reason_box">
                        <el-tooltip class="item" effect="dark" :content="list.premium_reason" placement="top">
                            <el-button style="border: 0;color: rgba(16, 140, 221, 1);">[查看原因]</el-button>
                        </el-tooltip>
                    </div>
                </div>
                <div v-else-if="list.premium_status === 1 || list.premium_status === 2 || list.premium_status === 3 || list.premium_status === 4 || list.premium_status === 5"
                    class="media_premium_box flex">
                    <p style="color: rgba(222, 13, 13, 1);margin-bottom: 5px;">￥{{ list.premium_price }}</p>
                    <p v-if="list.premium_status === 1" style="color: rgba(235, 173, 59, 1);">同意溢价</p>
                    <p v-if="list.premium_status === 2" style="color: rgba(235, 173, 59, 1);">拒绝溢价</p>
                    <p v-if="list.premium_status === 3" style="color: rgba(235, 173, 59, 1);">申请退款中...</p>
                    <p v-if="list.premium_status === 4" style="color: rgba(224, 25, 25, 1);">拒绝退款</p>
                    <p v-if="list.premium_status === 5" style="color: rgb(0, 176, 82)">同意退款</p>
                    <div class="reason_box">
                        <el-tooltip class="item" effect="dark" :content="list.premium_reason" placement="top">
                            <el-button style="border: 0;color: rgba(16, 140, 221, 1);">[查看原因]</el-button>
                        </el-tooltip>
                    </div>
                </div>
                <p v-else>-</p>
            </div>
        </div>
    </div>
</template>

<script>
import { refresh_user_info } from '@/util/refresh_user_info'
export default {
    props: {
        list: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        _prmium_(type) {
            let data = {}
            data.order_id = this.list.id
            data.id = this.list.premium_id
            data.status = type
            this.curlPost('/api/users/premium/edit', data).then(res => {
                if (res.data.code) {
                    this.$user_info = refresh_user_info()
                    this.$message({
                        message: type == 1 ? '同意溢价' : type == 2 ? '拒绝溢价' : type == 4 ? '拒绝退款' : '同意退款',
                        type: 'success'
                    })
                    this.$emit('getList')
                }

            })
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/poup'
</style>